* {
  font-size: 16px;
}

.page-header {
  a {
    text-decoration: none;
  }

  .profile-photo {
    padding-right: 15px;
    border: none;
  }

  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.social-sites-bar {
  i {
    font-size: 24px;
  }
}

.floating-img {
  float: left;
  width: 200px;
  margin-right: 10px;
  margin-top: 4px;
}

.menu {
  a {
    font-size: 20px;
  }
}

.left-border {
  border-left: 1px solid #ccc;
}

.tags {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;

  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.floating-image {
  float: left;
  padding-right: 10px;
}

.floating-image-right {
  float: right;
  padding-left: 10px;
  padding-right: 50px;
}

.floating-clear {
  clear: both;
}

.my-content {
  img {
    max-width: 96%;
    padding-left: 2%;
  }

  li {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

.highlight * {
  font-size: 14px;
}

blockquote {
  font-size: 16px;
}

table {
  td,
  th {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  tr {
    border-bottom: 1px solid #ccc;
  }
}
